<template>
  <b-table-simple
    hover
    caption-top
    caption-bottom
    responsive
    bordered
    small
  >
    <b-thead>
      <b-tr>
        <b-th class="text-center width-50">
          <FeatherIcon icon="EyeIcon" />
        </b-th>
        <b-th class="text-center width-50">
          <FeatherIcon icon="DownloadIcon" />
        </b-th>
        <b-th class="text-center width-100">
          AY
        </b-th>
        <b-th class="text-center">
          YIL
        </b-th>
        <b-th class="text-center">
          Satış Adet
        </b-th>
        <b-th
          class="text-center width-100"
          colspan="2"
        >
          Trafik
        </b-th>
        <b-th
          class="text-center width-100"
          colspan="2"
        >
          Kasko
        </b-th>
        <b-th
          class="text-center width-100"
          colspan="2"
        >
          Marka Kasko
        </b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <template v-for="(report,key) in reportData">
        <b-tr
          :key="key"
          :class="report.brandToggle? 'bg-light-warning' : ''"
        >
          <b-td>
            <b-button
              :variant="report.brandToggle? 'flat-warning' : 'flat-primary'"
              class="btn-icon"
              pill
              @click="toggleBrand(key)"
            >
              <FeatherIcon
                :icon="report.brandToggle? 'EyeOffIcon' : 'EyeIcon'"
              />
            </b-button>
          </b-td>
          <b-td>
            <b-button
              variant="flat-success"
              class="btn-icon"
              pill
              :href="downloadUrl(report.year,report.month)"
              target="_blank"
              :disabled="!report.sales_count"
            >
              <FeatherIcon
                icon="DownloadIcon"
              />
            </b-button>
          </b-td>
          <b-td class="text-center">
            {{ report.month }}
          </b-td>
          <b-td class="text-center">
            {{ report.year }}
          </b-td>
          <b-td class="text-center width-100">
            {{ report.sales_count | toNumber }}
          </b-td>
          <b-td class="text-center width-100">
            {{ report.trafik_count | toNumber }}
          </b-td>
          <b-td class="text-center text-danger font-weight-bold width-100">
            %{{ report.trafik_percent | toNumber }}
          </b-td>
          <b-td class="text-center width-100">
            {{ report.kasko_count | toNumber }}
          </b-td>
          <b-td class="text-center text-danger font-weight-bold width-100">
            %{{ report.kasko_percent | toNumber }}
          </b-td>
          <b-td class="text-center width-100">
            {{ report.marka_kasko_count | toNumber }}
          </b-td>
          <b-td class="text-center text-danger font-weight-bold width-100">
            %{{ report.marka_kasko_percent | toNumber }}
          </b-td>
        </b-tr>
        <template v-if="report.brandToggle">
          <template
            v-for="(brand,brandIndex) in report.brands"
          >
            <b-tr
              :key="'brand_' + key + '_' + brand.id"
              :class="brand.userToggle? 'bg-light-danger' : ''"
            >
              <b-td class="text-center">
                <b-button
                  :variant="brand.userToggle? 'flat-danger' : 'flat-info'"
                  class="btn-icon"
                  pill
                  :disabled="brand.users.length === 0"
                  @click="toggleUser(key,brandIndex)"
                >
                  <FeatherIcon :icon="brand.userToggle? 'EyeOffIcon' : 'EyeIcon'" />
                </b-button>
              </b-td>
              <b-td colspan="3">
                {{ brand.brand }}
              </b-td>
              <b-td class="text-center">
                {{ brand.sales_count | toNumber }}
              </b-td>
              <b-td class="text-center">
                {{ brand.trafik_count | toNumber }}
              </b-td>
              <b-td class="text-center text-danger font-weight-bold">
                %{{ brand.trafik_percent | toNumber }}
              </b-td>
              <b-td class="text-center">
                {{ brand.kasko_count | toNumber }}
              </b-td>
              <b-td class="text-center text-danger font-weight-bold">
                %{{ brand.kasko_percent | toNumber }}
              </b-td>
              <b-td class="text-center">
                {{ brand.marka_kasko_count | toNumber }}
              </b-td>
              <b-td class="text-center text-danger font-weight-bold">
                %{{ brand.marka_kasko_percent | toNumber }}
              </b-td>
            </b-tr>
            <template v-if="brand.userToggle">
              <template
                v-for="(user) in brand.users"
              >
                <b-tr
                  :key="'user_' + brand.id + '_' + user.id"
                >
                  <b-td class="text-center">
                    <FeatherIcon icon="UserIcon" />
                  </b-td>
                  <b-td colspan="3">
                    {{ user.username }}
                  </b-td>
                  <b-td class="text-center">
                    {{ user.sales_count | toNumber }}
                  </b-td>
                  <b-td class="text-center">
                    {{ user.trafik_count | toNumber }}
                  </b-td>
                  <b-td class="text-center text-danger font-weight-bold">
                    %{{ user.trafik_percent | toNumber }}
                  </b-td>
                  <b-td class="text-center">
                    {{ user.kasko_count | toNumber }}
                  </b-td>
                  <b-td class="text-center text-danger font-weight-bold">
                    %{{ user.kasko_percent | toNumber }}
                  </b-td>
                  <b-td class="text-center">
                    {{ user.marka_kasko_count | toNumber }}
                  </b-td>
                  <b-td class="text-center text-danger font-weight-bold">
                    %{{ user.marka_kasko_percent | toNumber }}
                  </b-td>
                </b-tr>
              </template>
            </template>
          </template>
        </template>
      </template>
    </b-tbody>
    <b-tfoot>
      <b-tr>
        <b-th colspan="4">
          Toplam
        </b-th>
        <b-th class="text-center">
          {{ totalData.sales_count | toNumber }}
        </b-th>
        <b-th class="text-center">
          {{ totalData.trafik_count | toNumber }}
        </b-th>
        <b-th class="text-center text-danger">
          %{{ totalData.trafik_percent | toNumber }}
        </b-th>
        <b-th class="text-center">
          {{ totalData.kasko_count | toNumber }}
        </b-th>
        <b-th class="text-center text-danger">
          %{{ totalData.kasko_percent | toNumber }}
        </b-th>
        <b-th class="text-center">
          {{ totalData.marka_kasko_count | toNumber }}
        </b-th>
        <b-th class="text-center text-danger">
          %{{ totalData.marka_kasko_percent | toNumber }}
        </b-th>
      </b-tr>
    </b-tfoot>
  </b-table-simple>
</template>
<script>
import {
  BButton, BTableSimple, BTbody, BTd, BTfoot, BTh, BThead, BTr,
} from 'bootstrap-vue'

export default {
  name: 'ReportTable',
  components: {
    BTableSimple,
    BTd,
    BTfoot,
    BTbody,
    BTh,
    BThead,
    BTr,
    BButton,
  },
  computed: {
    reportData() {
      return this.$store.getters['insuranceSalesReport/getReport']
    },
    totalData() {
      return this.$store.getters['insuranceSalesReport/getTotalData']
    },
  },
  methods: {
    downloadUrl(year, month) {
      return `${this.$store.state.app.baseURL}/exports/reports/insurance/insurance_export/excel?token=${localStorage.getItem('downloadToken')}&year=${year}&month=${month}`
    },
    toggleBrand(index) {
      this.reportData[index].brandToggle = !this.reportData[index].brandToggle
    },
    toggleUser(index, brandIndex) {
      this.reportData[index].brands[brandIndex].userToggle = !this.reportData[index].brands[brandIndex].userToggle
    },
  },
}
</script>
