<template>
  <b-row>
    <b-col>
      <b-form-group
        label="Lokasyon"
        label-for="id_com_location"
      >
        <v-select
          id="id_com_location"
          v-model="getFilterData.id_com_location"
          :options="locations"
          placeholder="Lokasyon"
          label="title"
          :reduce="item => item.id"
        />
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label="Ay"
        label-for="months"
      >
        <v-select
          id="months"
          v-model="getFilterData.months"
          :options="months"
          placeholder="Ay"
          multiple
          :close-on-select="false"
        />
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label="Yıl"
        label-for="year"
      >
        <v-select
          id="year"
          v-model="getFilterData.year"
          :options="years"
          placeholder="Yıl"
        />
      </b-form-group>
    </b-col>
    <b-col cols="auto">
      <b-button
        variant="primary"
        class="mt-2"
        @click="getReport"
      >
        <FeatherIcon icon="FilterIcon" />
        Görüntüle
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Filter',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    vSelect,
  },
  props: {
    getReport: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    }
  },
  computed: {
    getFilterData() {
      return this.$store.getters['insuranceSalesReport/getFilterData']
    },
    locations() {
      return this.$store.getters['locations/getLocations']
    },
    years() {
      return this.$store.getters['insuranceSalesReport/getYears']
    },
  },
  created() {
    this.getLocations()
  },
  methods: {
    getLocations() {
      this.$store.dispatch('locations/locationsList', {
        select: [
          'com_location.id AS id',
          'com_location.title AS title',
        ],
        where: {
          'com_location.id <=': 3,
        },
      })
    },
  },
}
</script>
