<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>
          Sıfır Satış
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <report-filter :get-report="getReport" />
      </b-card-body>
      <b-card-body v-if="loadingReport">
        <loading-report />
      </b-card-body>
      <report-table v-else />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue'
import ReportFilter from '@/views/Reports/Insurance/Sales/ReportFilter.vue'
import ReportTable from '@/views/Reports/Insurance/Sales/ReportTable.vue'
import LoadingReport from '@/views/Reports/Insurance/Sales/LoadingReport.vue'

export default {
  name: 'Sales',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    ReportFilter,
    ReportTable,
    LoadingReport,
  },
  computed: {
    getFilterData() {
      return this.$store.getters['insuranceSalesReport/getFilterData']
    },
    reportData() {
      return this.$store.getters['insuranceSalesReport/getReport']
    },
    loadingReport() {
      return this.$store.getters['insuranceSalesReport/getLoading']
    },
  },
  created() {
    this.getYears()
  },
  methods: {
    getYears() {
      this.$store.dispatch('insuranceSalesReport/years')
        .then(res => {
          if (res) {
            // eslint-disable-next-line prefer-destructuring
            this.getFilterData.year = res[0]
            this.getReport()
          }
        })
    },
    getReport() {
      this.$store.dispatch('insuranceSalesReport/report', {
        year: this.getFilterData.year,
        months: this.getFilterData.months,
        id_com_location: this.getFilterData.id_com_location,
      })
    },
  },
}
</script>
